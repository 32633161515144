
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import BasicButton from '@hems/component/src/buttons/BasicButton.vue';

export default defineComponent({
  name: 'NotFoundPage',
  components: {
    BasicButton,
  },
  setup() {
    const router = useRouter();

    const goHome = () => {
      router.push({
        path: '/',
      });
    };

    return {
      goHome,
    };
  },
});
